import { Slider } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

const WeightInput = ({
  inputRef,
  data,
  sortedCategories,
  setSortedCategories,
  foucusInput,
  updateCategory,
  setUpdateCategory,
}: {
  inputRef: React.MutableRefObject<any>;
  data: any;
  sortedCategories: any;
  setSortedCategories: any;
  foucusInput: any;
  updateCategory: any;
  setUpdateCategory: any;
}) => {
  const [inputValue, setInputValue] = useState<any>("");

  const handleChangeWeight = (e: any) => {
    e.stopPropagation();
    if (e.target.value > 100) {
      toast("Weight should be less than 100%", {
        type: "error",
      });
      return;
    } else {
      const updatedData = updateCategory.map((item: any) => {
        if (item.id === data.id) {
          return {
            ...item,
            weight: Number(e.target.value),
          };
        }
        return item;
      });

      setInputValue(e.target.value);
      // setSortedCategories(updatedData);
      setUpdateCategory(updatedData);

      setTimeout(() => {
        foucusInput(data.id);
      }, 50);
    }
  };

  useEffect(() => {
    const findData = updateCategory?.find((item: any) => item.id === data.id);
    if (findData) {
      setInputValue(Math?.round(findData?.weight));
    } else {
      setInputValue(Math?.round(data.weight));
    }
  }, [data, updateCategory]);

  const formatter = (value: number) => `${value}%`;

  return (
    <WeightContainer>
      <Slider
        min={0}
        max={100}
        onChange={(e: number) => {
          setInputValue(e);
        }}
        onAfterChange={(e: number) => {
          const updatedData = updateCategory.map((item: any) => {
            if (item.id === data.id) {
              return {
                ...item,
                weight: Number(e),
              };
            }
            return item;
          });

          setUpdateCategory(updatedData);
        }}
        handleRender
        value={Number(inputValue) || 0}
        style={{ width: "100%" }}
        className="tooltip-slider"
        thumbClassName="example-thumb"
        trackClassName="example-track"
        trackStyle={{ backgroundColor: "#1271A6" }}
        // @ts-ignore
        tipFormatter={formatter}
        // tooltipPlacement="top"
        handleStyle={{
          backgroundColor: "#fff",
          border: "1px solid #1271A6",
          marginTop: -8,
          width: 25,
          backgroundImage:
            "url(https://res.cloudinary.com/zaafir-solutions/image/upload/v1676582445/sliderDrag_crn5q6.svg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          borderRadius: 25,
          height: 25,
        }}
      />

      <WeightTableInput>
        <input
          type="number"
          ref={(el) => (inputRef.current[data.id] = el)}
          value={inputValue}
          onChange={handleChangeWeight}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            const invalidKeys = ["-", "+", "e", "E", "."];

            if (invalidKeys.includes(e.key)) {
              e.preventDefault();
            }

            const target = e.currentTarget;
            if (e.key === "ArrowDown") {
              const inputValue = parseFloat(target.value) || 0;
              if (inputValue <= 0) {
                e.preventDefault();
              }
            }
          }}
          placeholder="0"
        />

        <span>%</span>
      </WeightTableInput>
    </WeightContainer>
  );
};

export default WeightInput;

const WeightContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;

export const WeightTableInput = styled.div`
  position: relative;
  width: 62px;
  height: 28px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    border: none;
    outline: none;
    width: 62px;
    height: 28px;
    border-radius: 4px;
    background-color: #ffffff;
    border: 0.5px solid #cdcdcd;
    padding: 4px 20px 4px 6px;
  }

  span {
    position: absolute;
    right: 8px;
    color: #a3a3a3;
    font-size: 12px;
    font-weight: 400;
    border: none;
    top: 4.5px;
  }
`;
