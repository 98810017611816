import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// @ts-ignore
import DeleteModal from "../../Modal/DeleteModal";

import {
  deleteActionItem,
  deletePlanCategory,
  // @ts-ignore
} from "modules/actions/PlanActions";

import trashIcon from "./trash.svg";

import { Button } from "./styled";
import { toast } from "react-toastify";

interface IDeleteItemModal {
  itemId: number;
  itemName: string;
  planId: number;
  isVisible: boolean;
  type: "action" | "category";
  onClose: () => void;
  setSumOfWeight?: (value: number) => void;
  sumOfWeight?: number;
  updateCategory?: any;
  setUpdateCategory?: any;
}

export const DeleteItemModal: FC<IDeleteItemModal> = ({
  itemId,
  itemName,
  planId,
  isVisible,
  type = "action",
  onClose,
  setSumOfWeight,
  sumOfWeight,
  updateCategory,
  setUpdateCategory,
}) => {
  const dispatch = useDispatch();

  // @ts-ignore
  const { planDetails } = useSelector((state) => state.plan.plan);

  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    if (planDetails?.categories?.length <= 1 && type !== "action") {
      toast("You can't delete the last category", { type: "error" });
      onClose();
      return;
    }

    setIsLoading(true);
    if (type === "action")
      dispatch(deleteActionItem(itemId, planId, handleCallback));
    else dispatch(deletePlanCategory(itemId, planId, handleCallback));
  };

  const handleCallback = () => {
    setIsLoading(false);
    onClose();

    const findWeight = updateCategory?.find((item: any) => item.id === itemId);
    if (findWeight && updateCategory && setUpdateCategory) {
      setUpdateCategory(
        updateCategory.filter((item: any) => item.id !== itemId)
      );
    }
    if (findWeight?.weight && sumOfWeight && setSumOfWeight) {
      setSumOfWeight(sumOfWeight - findWeight?.weight);
    }
  };

  return (
    <DeleteModal
      visible={isVisible}
      isAction
      heading={type === "action" ? "Delete Action Item" : "Delete Category"}
      onClose={onClose}
      descriptions={
        type === "action" ? (
          <span>
            Are you sure you want to delete the "<b>{itemName}</b>" action item?
            All related information pertaining to the current action item will
            be lost.
          </span>
        ) : (
          <span>
            Are you sure you want to delete the category "<b>{itemName}</b>"?
            Deleting it will result in the loss of all related information.
          </span>
        )
      }
      handleRemove={handleDelete}
      loading={isLoading}
    />
  );
};

interface IDeleteItem {
  itemId: number;
  itemName: string;
  planId: number;
  isVisible?: boolean;
  type: "action" | "category";
}

const DeleteItem: FC<IDeleteItem> = ({
  itemId,
  itemName,
  planId,
  isVisible = false,
  type,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleDelete = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button
        onClick={handleDelete}
        title="Delete item"
        $visible={isVisible}
        data-testid="delete-button"
      >
        <img src={trashIcon} alt="" />
      </Button>

      <DeleteItemModal
        itemId={itemId}
        itemName={itemName}
        planId={planId}
        isVisible={isModalVisible}
        type={type}
        onClose={handleClose}
      />
    </>
  );
};

export default DeleteItem;
