import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tooltip, Popover } from "antd";
import cc from "classnames";
import { ReactComponent as ArrowDown } from "../../../../assets/svg/arrowDown.svg";
import { ReactComponent as ArrowUp } from "../../../../assets/svg/ArrowUp.svg";


import {
  CreateOptionCriteria,
  DecisionDetailHeader,
  FinalizeDecision,
  ManageCriteria,
} from "scenes/DecisionMatrix";

import {
  AddOptionsModal,
  AIAssistantModal,
  AIGenerateCriteriaModal,
  // Option,
  AIScoringRubicModal,
  // Select,
  WinnerModal,
} from "../../components";
import { AppLoading } from "components/AppLoading";
import { AppLayout } from "components/index";

// Icons
import { ReactComponent as AddIcon } from "./add.svg";
// import { ReactComponent as CheckedIcon } from "./checked.svg";

import { ReactComponent as Decending } from "./decending.svg";
import { ReactComponent as Assending } from "./assending.svg";

// import { ReactComponent as InfoIcon } from "./info.svg";

import { EllipsisOutlined, OrderedListOutlined } from "@ant-design/icons";

import OptionCriteria from "./OptionCriteria";
import CriteriaHead from "./CriteriaHead";

// Actions
import {
  GetDecisionDetails,
  SetDecisionDetails,
  GetSharedDecisionDetails
} from "redux/DecisionMatrix/GetDecisionDetails/action";
// import { AddToShortlist } from "redux/DecisionMatrix/AddToShortlist/action";
// import { RemoveFromShortlist } from "redux/DecisionMatrix/RemoveFromShortlist/action";
import { AddOptionRows } from "redux/DecisionMatrix/AddOptionRows/action";
import { SetColumnsToShow } from "redux/DecisionMatrix/SetColumnsToShow/action";

// Styles
import classes from "./decisiondetails.module.scss";
import HelpPopup from "components/HelpPopup";
import { getCookie, removeCookie, setCookie } from "utils/StorageVariables";

import { GetOptionCriteria } from "redux/DecisionMatrix/GetOptionCriteria/action";
import { GetShareDecision } from "redux/DecisionMatrix/GetShareDecision/action";
import { UpdateCriteriaRows } from "redux/DecisionMatrix/UpdateCriteriaRows/action";
// import { GetSharedDecisionDetails } from "redux/DecisionMatrix/GetSharedDecisions/action";

import OptionHead from "./OptionHead";
import ViewOnly from './ViewOnly';
import { useAllocatorAccess } from "../../../../utils/utility";

let isScrolling;

const DecisionDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { decisionId } = useParams();
  const query = useQuery();

  const is_sample = query.get("is_sample");
  const is_shared = pathname.includes("shared-decision") || pathname.includes("shared/");
  const secure_token = query.get("secure_token");
  const clone_decision = query.get("clone");

  const [isAddOption, setIsAddOptions] = useState(false);
  // const [isShowManageCriteria, setIsShowManageCriteria] = useState(false);
  const [isShowFinalizeDecisin, setIsShowFinalizeDecisin] = useState(false);
  const [isShowWinner, setIsShowWinner] = useState(false);
  const [options, setOptions] = useState([]);
  // const [selectIndex, setSelectIndex] = useState(null);
  const [sort, setSort] = useState({
    type: null,
    value: null,
    shortlist: false,
  });
  const [optionsOrder, setOptionsOrder] = useState([]);
  // const [shortlistedIds, setShortlistedIds] = useState([]);
  // const [unShortlistedIds, setUnShortlistedIds] = useState([]);
  const [criteriaForChanging, setCriteriaForChanging] = useState({
    showPopup: false,
  });
  const [expandedFacts, setExpandedFacts] = useState({});
  const [activeCriterias, setActiveCriterias] = useState([]);
  const [selectedCriteria, setSelectedCriteria] = useState(null);
  const [decision, setDecision] = useState({});
  const [value, setValue] = useState(1);
  const [width, setWidth] = useState(730);
  const [isShortList, setIsShortList] = useState(false);
  const [isFinalists, setIsFinalists] = useState(false);
  const [aiAssistant, setAiAssistant] = useState(false);
  const [isScoringRubicModal, setIsScoringRubicModal] = useState(false);
  const [isGenerateCriteriaModal, setIsGenerateCriteriaModal] = useState(false);
  const [pros, setPros] = useState([]);
  const [cons, setCons] = useState([]);

  const isAllocatorUser = useAllocatorAccess();
  const [ids, setIds] = useState([]);
  const [CriteriaPayload, setCriteriaPayload] = useState([])
  const [scoreData, setScoreData] = useState([])
  const [factsPayload, setFactsPayload] = useState([])

  // const [criteriaId, setCriteriaId] = useState();
  // const [isTrue, setIsTrue] = useState(false);

  const $input = useRef();

  const $options = useRef();
  const $criterias = useRef();
  const $scrollbarY = useRef();
  const $scrollbarX = useRef();
  const $criteriasHead = useRef();
  const isUserScrolling = useRef(false);
  const [items, setItems] = useState([]);

  const updateItems = (newItem) => {
    setItems((prevItems) => {
      const existingIndex = prevItems.findIndex(item => item.id === newItem.id);

      if (existingIndex !== -1) {
        // Replace the existing item
        const updatedItems = [...prevItems];
        updatedItems[existingIndex] = newItem;
        return updatedItems;
      } else {
        // Add the new item
        return [...prevItems, newItem];
      }
    });
  };

  const { success: userDecisions, loading } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );

  const { columnsToShow } = useSelector((state) => state.columnsToShowReducer);
  const { success: optionCriteria } = useSelector(
    (state) => state.getOptionCriteriaReducer
  );


  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  useEffect(() => {
    if ($input && $input.current) {
      $input.current.focus();
    }
  }, []);

  useEffect(() => {
    if (aiAssistant) {
      dispatch(GetOptionCriteria());
    }
  }, [aiAssistant]);

  const setOptionCriteriaProsCons = (key, value) => {
    setSelectedCriteria(value);
    if (value) {
      const found = optionCriteria?.find((e) => e?.criteria?.id === value);
      setPros(found?.pros);
      setCons(found?.cons);
    } else {
      setPros([]);
      setCons([]);
    }
  };

  const { success: shareDecision } = useSelector(
    (state) => state.getShareDecisionReducer
  );

  useEffect(() => {
    if (!decisionId)
      return;

    if (is_shared && isAllocatorUser) {
      dispatch(GetSharedDecisionDetails(decisionId));
    }
    else if (secure_token) {
      dispatch(GetShareDecision(secure_token, decisionId));
    }
    else if (!clone_decision) {
      dispatch(GetDecisionDetails(decisionId, true, is_sample, true, error => {
        console.error(error);
        if (error?.response?.status === 404) {
          navigate("/");
        }
      }));
    }

    return () => {
      dispatch(SetDecisionDetails(null));
    };
  }, [decisionId, secure_token, is_shared, isAllocatorUser]);

  useEffect(() => {
    setWidth(
      490 +
      (columnsToShow?.prosCons ? 80 : 0) +
      // (columnsToShow?.adjustedScore ? 80 : 0) +
      (columnsToShow?.weightedScore ? 80 : 0)
    );
  }, [columnsToShow]);

  const filterShortlisted = (key) => {
    if (key === undefined) return options;
    const FilterCookie = JSON.parse(getCookie("isAscending"));
    const currentFilter = FilterCookie?.find(
      (item) => item?.decId === decisionId
    );
    if (currentFilter[key]) return options?.filter((item) => item[key]);
    return options;
  };

  useEffect(() => {
    const sec_token = query.get("secure_token");
    setCookie("secure_token", sec_token);
  }, []);

  useEffect(() => {
    return () => {
      removeCookie("secure_token");
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if ($criterias.current) {
        const tableHeight =
          document.querySelector("#tableWrapper").offsetHeight;
        const elementWidth =
          $criterias.current.querySelector("#criterias").offsetWidth;
        const elementHeight =
          $criterias.current.querySelector("#criteriasBody").offsetHeight;

        $scrollbarX.current.querySelector(
          "span"
        ).style.width = `${elementWidth}px`;
        $scrollbarY.current.querySelector(
          "span"
        ).style.height = `${elementHeight}px`;

        $scrollbarY.current.style.display = tableHeight < elementHeight ? "block" : "none";
      }
    }, 100);
  }, [userDecisions, expandedFacts, columnsToShow]);

  useEffect(() => {
    const handleResize = () => {
      const tableHeight = document.querySelector("#tableWrapper").offsetHeight;
      const elementHeight = $criterias.current.querySelector("#criteriasBody").offsetHeight;

      $scrollbarY.current.style.display = tableHeight < elementHeight ? "block" : "none";
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScrollbarX = (e) => {
      if (
        e.target.scrollLeft !== "undefined" &&
        (!isUserScrolling.current || isUserScrolling.current === "scrollbar-x")
      ) {
        isUserScrolling.current = "scrollbar-x";
        $criterias.current.scrollLeft = e.target.scrollLeft;
        $criteriasHead.current.style.left = `${width - e.target.scrollLeft}px`;

        clearTimeout(isScrolling);

        isScrolling = setTimeout(() => {
          isUserScrolling.current = null;
        }, 200);
      }
    };

    const handleScrollbarY = (e) => {
      if (
        e.target.scrollLeft !== "undefined" &&
        (!isUserScrolling.current || isUserScrolling.current === "scrollbar-y")
      ) {
        isUserScrolling.current = "scrollbar-y";
        $options.current.scrollTop = e.target.scrollTop;
        $criterias.current.scrollTop = e.target.scrollTop;

        clearTimeout(isScrolling);

        isScrolling = setTimeout(() => {
          isUserScrolling.current = null;
        }, 200);
      }
    };

    const handleCriteriasScroll = (e) => {
      if (
        e.target.scrollLeft !== "undefined" &&
        (!isUserScrolling.current || isUserScrolling.current === "criteria")
      ) {
        isUserScrolling.current = "criteria";
        $scrollbarX.current.scrollLeft = e.target.scrollLeft;
        $criteriasHead.current.style.left = `${width - e.target.scrollLeft}px`;

        clearTimeout(isScrolling);

        isScrolling = setTimeout(() => {
          isUserScrolling.current = null;
        }, 200);
      }

      if (
        e.target.scrollTop !== "undefined" &&
        (!isUserScrolling.current || isUserScrolling.current === "criteria")
      ) {
        isUserScrolling.current = "criteria";
        $options.current.scrollTop = e.target.scrollTop;
        $scrollbarY.current.scrollTop = e.target.scrollTop;

        clearTimeout(isScrolling);

        isScrolling = setTimeout(() => {
          isUserScrolling.current = null;
        }, 200);
      }
    };

    const handleOptionsScroll = (e) => {
      if (
        e.target.scrollTop !== "undefined" &&
        (!isUserScrolling.current || isUserScrolling.current === "option-y")
      ) {
        isUserScrolling.current = "option-y";
        $criterias.current.scrollTop = e.target.scrollTop;
        $scrollbarY.current.scrollTop = e.target.scrollTop;

        clearTimeout(isScrolling);

        isScrolling = setTimeout(() => {
          isUserScrolling.current = null;
        }, 200);
      }
    };

    if ($criterias.current) {
      $scrollbarX.current.addEventListener("scroll", handleScrollbarX);
      $scrollbarY.current.addEventListener("scroll", handleScrollbarY);

      $criterias.current.addEventListener("scroll", handleCriteriasScroll);
      $options.current.addEventListener("scroll", handleOptionsScroll);
    }

    return () => {
      $scrollbarX &&
        $scrollbarX.current &&
        $scrollbarX.current.removeEventListener("scroll", handleScrollbarX);
      $scrollbarY &&
        $scrollbarY.current &&
        $scrollbarY.current.removeEventListener("scroll", handleScrollbarY);

      $criterias &&
        $criterias.current &&
        $criterias.current.removeEventListener("scroll", handleCriteriasScroll);
      $options &&
        $options.current &&
        $options.current.removeEventListener("scroll", handleOptionsScroll);
    };
  }, [options, width]);

  /* useEffect(() => {
    // Scroll to top jugaar
    if ($criterias.current && !isTrue) {
      $criterias.current.scrollTop = 0;
      $options.current.scrollTop = 0;
      $scrollbarY.current.scrollTop = 0;
    }
  }, [options, width]); */

  // useEffect(() => {
  //   if (!options) return;
  //   setShortlistedIds([
  //     ...options?.filter((item) => item?.shortlist)?.map((item) => item?.id),
  //   ]);
  //   setUnShortlistedIds([
  //     ...options?.filter((item) => !item?.shortlist)?.map((item) => item?.id),
  //   ]);
  // }, [options]);

  useEffect(() => {
    setDecision(userDecisions);
  }, [userDecisions]);

  useEffect(() => {
    if (!decision?.options) return;
    if (
      (decision?.options?.length && !sort?.type && !optionsOrder?.length) ||
      decision?.options?.length !== optionsOrder?.length
    ) {
      setOptions(
        [...decision?.options]
          ?.sort((a, b) => a?.id - b?.id)
          ?.map((option, i) => ({
            ...option,
            order: i,
          }))
      );
      setOptionsOrder(
        [...decision?.options]
          ?.sort((a, b) => b?.id - a?.id)
          ?.map((option, i) => ({
            id: option?.id,
            order: i,
          }))
      );
    } else if (optionsOrder?.length) {
      setOptions(
        [...decision?.options]
          ?.map((option) => ({
            ...option,
            order: optionsOrder?.find((o) => o?.id === option?.id)?.order,
          }))
          ?.sort((a, b) => b?.order - a?.order)
      );
    }
  }, [decision?.options, optionsOrder]);

  useEffect(() => {
    let sortingType = JSON.parse(getCookie("isAscending"));
    let obj = sortingType?.find((item) => item?.decId === decisionId);

    if (obj?.decId === decisionId) {
      // setSort({ type: obj?.type, value: obj?.value });
      if (sortingType?.finalist) {
        setIsFinalists(obj?.finalist);
      } else if (sortingType?.shortlist) {
        setIsShortList(obj?.shortlist);
      }
    }
  }, [decision?.options]);

  useEffect(() => {
    // Function to get the initial sorting state from the cookies (if any)
    const getInitialSortingState = (type) => {
      const existingCookies = JSON.parse(getCookie("isAscending")) || [];
      const existingIndex = existingCookies.findIndex(
        (cookie) => cookie.decId === decisionId
      );

      if (existingIndex !== -1) {
        // Set the initial sorting state from the cookies
        // setSort(existingCookies[existingIndex]);
      } else {
        // No existing sorting state found, set a default sorting state (if needed)
        // For example, if you want to set a default type and value when no cookies are found
        // setSort({ type, value: "asc" });
        let updatedCookies = [
          {
            type,
            value: sort.value === "asc" ? "desc" : "asc",
            decId: decisionId,
          },
        ];

        setCookie("isAscending", JSON.stringify(updatedCookies));
      }
    };

    // Call the function to get the initial sorting state on component mount
    getInitialSortingState("weight");
  }, []);

  const sortingHandle = (sortKey, criteriaId) => {
    const sortKeyVal = sortKey || sort?.value;
    const options = decision?.options || [];

    if (!options.length)
      return;

    const getScore = (option, criteriaId) => {
      if (criteriaId) {
        const criteriaOption = option.option_criteria.find(oc => oc.criteria === criteriaId);
        return parseInt(isShortList ? criteriaOption?.second_stage_score : criteriaOption?.score || 0);
      }
      return parseInt(isShortList ? option?.second_stage_weighted_score : option?.weighted_score || 0);
    };

    const sortOptions = (a, b) => {
      if (sortKeyVal === "desc") {
        return getScore(b, criteriaId) - getScore(a, criteriaId);
      }
      if (sortKeyVal === "asc") {
        return getScore(a, criteriaId) - getScore(b, criteriaId);
      }
      if (sortKeyVal === "no") {
        return b?.id - a?.id;
      }
      return 0; // Default case if no valid sortKeyVal is provided
    };

    const orderedOptions = options
      .sort(sortOptions)
      .map((option, index) => ({
        id: option?.id,
        order: index,
      }));

    setOptionsOrder(orderedOptions);
  };

  const onSortingClickHandle = (type) => () => {
    let value = 'no'
    if (sort.value == "desc") {
      value = "asc"
    } else if (sort.value == "asc") {
      value = "no"
    } else if (sort.value == "no" || !sort?.value) {
      value = "desc"
    }
    sortingHandle(value);
    const existingCookies = JSON.parse(getCookie("isAscending")) || [];
    const existingIndex = existingCookies.findIndex(
      (cookie) => cookie.decId === decisionId
    );

    let updatedCookies;

    if (existingIndex !== -1) {
      updatedCookies = existingCookies.map((cookie) => {
        if (cookie.decId === decisionId) {
          return {
            ...cookie,
            type,
            value: sort.value === "asc" ? "desc" : "asc",
          };
        }
        return cookie;
      });
    } else {
      updatedCookies = [
        ...existingCookies,
        {
          type,
          value: sort.value === "asc" ? "desc" : "asc",
          decId: decisionId,
        },
      ];
    }

    // Reset sorting by criteria
    dispatch(
      SetColumnsToShow({
        ...columnsToShow,
        sortingCriteriaId: null,
      })
    );

    setSort({ type, value: value });
    setCookie("isAscending", JSON.stringify(updatedCookies));
  };

  const onSortingClickHandlePopup = (criteriaId, val, status) => {
    dispatch(
      SetColumnsToShow({
        ...columnsToShow,
        isAscending: status,
        sortingCriteriaId: criteriaId,
      })
    );

    sortingHandle(val, criteriaId);

    setSort({
      type: null,
      value: null,
      shortlist: false,
    });
  };

  /* const removeShortlist = () => {
    if (!unShortlistedIds?.length) return;
    let alreadyUnShortlisted = options
      ?.filter((option) => !option?.shortlist)
      ?.map((option) => option?.id);
    let payload = unShortlistedIds
      ?.filter((item) => !alreadyUnShortlisted?.includes(item))
      ?.join(",");
    if (!payload) return;
    dispatch(
      RemoveFromShortlist(
        {
          options: payload,
        },
        decisionId,
        resetCheckList
      )
    );
  }; */

  const onSubmitHandle = (event) => {
    event.preventDefault();

    dispatch(
      AddOptionRows({ num_rows: value, decision_id: decisionId }, setValue)
    );
  };

  useEffect(() => {
    if (decision && decision.criteria)
      setActiveCriterias(
        decision?.criteria
          ?.filter((c) => c.active)
          .sort((a, b) => a.id - b.id)
          .sort((a, b) => b.weight - a.weight)
      );
  }, [decision]);

  /* const hanldeCheckbox = (id) => {
    if (shortlistedIds?.includes(id)) {
      setShortlistedIds([...shortlistedIds?.filter((item) => item !== id)]);
      setUnShortlistedIds([...unShortlistedIds, id]);
    } else {
      setUnShortlistedIds([...unShortlistedIds?.filter((item) => item !== id)]);
      setShortlistedIds([...shortlistedIds, id]);
    }
  }; */

  /* const handleCheckedAll = () => {
    if (shortlistedIds?.length === options?.length) {
      setShortlistedIds([]);
      setUnShortlistedIds([options?.map((option) => option?.id)]);
    } else {
      setShortlistedIds([...options?.map((option) => option?.id)]);
      setUnShortlistedIds([]);
    }
  }; */

  /* const handleCheckedAllUnshortlisted = () => {
    if (unShortlistedIds?.length === options?.length) {
      setShortlistedIds([options?.map((option) => option?.id)]);
      setUnShortlistedIds([]);
    } else {
      setShortlistedIds([]);
      setUnShortlistedIds([...options?.map((option) => option?.id)]);
    }
  }; */

  /* const handleShortlist = () => {
    if (!shortlistedIds?.length) return;
    let alreadyShortlisted = options
      ?.filter((option) => option?.shortlist)
      ?.map((option) => option?.id);
    let payload = shortlistedIds
      ?.filter((item) => !alreadyShortlisted?.includes(item))
      ?.join(",");
    if (!payload) return;
    dispatch(
      AddToShortlist(
        {
          options: payload,
        },
        decisionId,
        resetCheckList
      )
    );
  }; */

  /* const resetCheckList = () => {
    setShortlistedIds([]);
    setUnShortlistedIds([]);
  }; */

  // const handleActiveCriteria = (criteria) => {
  //   setActiveCriterias(
  //     activeCriterias?.map((c) =>
  //       c?.id !== criteria?.id ? { ...c, active: !c.active } : c
  //     )
  //   );
  // };

  // const menuProps = {
  //   items: [
  //     {
  //       label: "1st menu item",
  //       key: "1",
  //     },
  //     {
  //       label: "2st menu item",
  //       key: "2",
  //     },
  //   ],
  //   onClick: () => {},
  // };

  const handleColumnToShow = (_, key) => {
    dispatch(
      SetColumnsToShow({
        ...columnsToShow,
        [key]: !columnsToShow[key],
      })
    );
  };

  const secondStageScoreIdsToCheck = useMemo(() => {
    let optionsOfCriteria = [];

    activeCriterias.forEach((criteria) => {
      let criteriaOptions = [];

      options
        .filter((option) => option.shortlist)
        .forEach((option) => {
          const rawCriteriaOptions = option.option_criteria.filter(
            (oc) => oc.criteria === criteria.id
          );
          criteriaOptions = [...criteriaOptions, ...rawCriteriaOptions];
        });

      const filteredArray = criteriaOptions.filter((item, index, self) => {
        // Return true if the item has a score field that is duplicated in the array
        return self.some((otherItem, otherIndex) => {
          return (
            otherItem.second_stage_score === item.second_stage_score &&
            otherIndex !== index &&
            item.second_stage_score !== "0.0"
          );
        });
      });

      optionsOfCriteria = [...optionsOfCriteria, ...filteredArray];
    });

    return optionsOfCriteria;
  }, [options, activeCriterias]);

  const criteriaWeightMap = activeCriterias.reduce((map, crit) => {
    map[crit.id] = crit.weight;
    return map;
  }, {});

  const criteriaTitleMap = activeCriterias.reduce((map, crit) => {
    map[crit.id] = crit.title;
    return map;
  }, {});

  const toggleCookie = () => {
    const FilterCookie = JSON.parse(getCookie("isAscending"));
    const currentFilter = FilterCookie?.find(
      (item) => item?.decId === decisionId
    );
    return (
      (currentFilter?.shortlist && "shortlist") ||
      (currentFilter?.finalist && "finalist")
    );
  };

  const handleCriteriaChange = options => data => {
    setCriteriaForChanging({ options, ...data });
  }

  const handleRubricSave = (rubrics, criteria) => {
    dispatch(
      UpdateCriteriaRows({
        update: userDecisions?.criteria
          ?.filter((crit) => crit?.id === criteria)
          ?.map(({ rubric, ...rest }) => {
            return { ...rest, rubric: rubrics };
          }),
        decision: decisionId,
      })
    );
  }

  return (
    <div>
      <div className={cc(classes.body)}>
        <AppLayout>
          {is_shared ? <ViewOnly
            id={isAllocatorUser ? decision?.hashed_id : decisionId}
            isCloning={clone_decision}
          /> : null}

          {!clone_decision && <DecisionDetailHeader
            // setIsShowManageCriteria={setIsShowManageCriteria}
            setIsShowFinalizeDecisin={setIsShowFinalizeDecisin}
            options={options}
            setIsShortList={setIsShortList}
            isShortList={isShortList}
            isShared={is_shared}
            setIsFinalists={setIsFinalists}
            isFinalists={isFinalists}
            handleColumnToShow={(key) =>
              handleColumnToShow(columnsToShow[key], key)
            }
            activeCriterias={activeCriterias}
            columnsToShow={columnsToShow}
            sort={sort}
          />}

          <HelpPopup />
          <AppLoading loading={loading} />

          {loading ? null : (
            <div className={classes.tableContainer}>
              <div className={classes.tableWrapper} id="tableWrapper">
                <div className={classes.table}>
                  <div className={classes.tableMainWrapper}>
                    <div className={classes.tableMainHead}>
                      <div className={classes.column}>
                        <div className={classes.box2Title}>
                          <div className="d-flex align-items-center">
                            <span>Options</span>

                            <button
                              disabled={is_sample || secure_token || is_shared}
                              type="button"
                              className={classes.addOptionsButton}
                              onClick={() => setIsAddOptions(true)}
                            >
                              <AddIcon />
                              Add Options
                            </button>
                          </div>

                          <Popover
                            overlayClassName="optionHeaderPopover"
                            placement="bottomLeft"
                            content={
                              <div className="popoverOptionsWrapper">
                                <div className="cursor-pointer popoverOptionsDiv">
                                  <div className="d-flex align-items-center w-100  ">
                                    <input
                                      type="checkbox"
                                      checked={!columnsToShow.weightedScore}
                                      className="option_checkbox me-3"
                                      onChange={(e) =>
                                        handleColumnToShow(
                                          e.target.checked,
                                          "weightedScore"
                                        )
                                      }
                                    />
                                    Hide Weighted Score
                                  </div>
                                </div>
                                <div className="cursor-pointer popoverOptionsDiv">
                                  <div className="d-flex align-items-center w-100  ">
                                    <input
                                      type="checkbox"
                                      className="option_checkbox me-3"
                                      checked={!columnsToShow.adjustedScore}
                                      onChange={(e) =>
                                        handleColumnToShow(
                                          e.target.checked,
                                          "adjustedScore"
                                        )
                                      }
                                    />
                                    Hide Adjusted Score
                                  </div>
                                </div>
                                <div className="cursor-pointer popoverOptionsDiv">
                                  <div className="d-flex align-items-center w-100  ">
                                    <input
                                      type="checkbox"
                                      className="option_checkbox me-3"
                                      checked={!columnsToShow.prosCons}
                                      onChange={(e) =>
                                        handleColumnToShow(
                                          e.target.checked,
                                          "prosCons"
                                        )
                                      }
                                    />
                                    Hide Pros/Cons
                                  </div>
                                </div>
                                <div
                                  onClick={() =>
                                    dispatch(
                                      SetColumnsToShow({
                                        prosCons: true,
                                        adjustedScore: true,
                                        weightedScore: true,
                                      })
                                    )
                                  }
                                  className="cursor-pointer popoverOptionsDiv"
                                >
                                  Default View
                                </div>
                              </div>
                            }
                            trigger="click"
                          >
                            <EllipsisOutlined
                              className={classes.popover}
                              // onClick={(e) => {
                              //   showModal(e);
                              // }}
                              rotate={90}
                            />
                          </Popover>
                        </div>
                      </div>

                      {columnsToShow?.prosCons && (
                        <div className={classes.column}>
                          <button
                            type="button"
                            className={classes.box1Title}
                            disabled
                          >
                            <span>
                              Pros
                              <br />
                              Cons
                            </span>
                          </button>
                        </div>
                      )}

                      {columnsToShow.weightedScore && (
                        <div className={classes.column} >
                          <button
                            style={{ justifyContent: "space-between", gap: "10px", paddingLeft: "13px" }}
                            type="button"
                            className={classes.box1Title}
                            onClick={onSortingClickHandle("weight")}
                            title="Sort"
                          >
                            <span>Score</span>

                            {sort.type !== "weight" && <OrderedListOutlined />}
                            {sort.type === "weight" && sort.value === 'no' && <OrderedListOutlined />}
                            {sort.type === "weight" && sort.value === "desc" && (
                              <Assending />
                            )}
                            {sort.type === "weight" &&
                              sort.value === "asc" && <Decending />}
                          </button>
                        </div>
                      )}
                    </div>
                    <div
                      className={cc(classes.tableMain, {
                        [classes.at]: isAllocatorUser,
                        [classes.readonly]: is_shared,
                      })}
                      ref={$options}
                      style={{ width: width }}
                    >
                      <div className={classes.tableMainContent} id="options">
                        {filterShortlisted(toggleCookie())?.map(
                          (option, index) => <OptionHead
                            option={option}
                            columnsToShow={columnsToShow}
                            isShortList={isShortList}
                            decisionId={decision?.id}
                            index={index}
                            isShared={is_shared}
                            key={option?.id}
                            errorIds={ids}
                            setError={setIds}
                            items={items}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className={cc(classes.criteriasWrapperMain, { [classes.at]: isAllocatorUser, [classes.readonly]: is_shared })}
                    ref={$criterias}
                    style={{ width: `calc(100% - ${width - 2})` }}
                  >
                    <div className={classes.criteriasContent} id="criterias">
                      <div
                        className={classes.criteriasHeader}
                        ref={$criteriasHead}
                      >
                        {activeCriterias?.map(c => (
                          <CriteriaHead
                            data={c}
                            columnsToShow={columnsToShow}
                            onSort={onSortingClickHandlePopup}
                            key={c?.id}
                          />
                        ))}
                      </div>

                      <div className={classes.criteriasBody} id="criteriasBody">
                        {filterShortlisted(toggleCookie())?.map(
                          (option, index) => {
                            const filteredOptionCriteria =
                              option.option_criteria.reduce(
                                (filtered, optionCriteria) => {
                                  const crit = activeCriterias.find(
                                    (c1) => c1.id === optionCriteria.criteria
                                  );
                                  if (crit) {
                                    filtered.push({
                                      ...optionCriteria,
                                      weight: criteriaWeightMap[crit.id],
                                      title: criteriaTitleMap[crit.id],
                                    });
                                  }
                                  return filtered;
                                },
                                []
                              );

                            const sortedOptionCriteria = filteredOptionCriteria
                              .sort((oc1, oc2) => oc1.criteria - oc2.criteria)
                              .sort(
                                (oc1, oc2) =>
                                  criteriaWeightMap[oc2.criteria] -
                                  criteriaWeightMap[oc1.criteria]
                              );

                            return (
                              <div
                                key={option.id}
                                className={classes.criteriasWrapper}
                              >
                                {sortedOptionCriteria.map(
                                  (optionCriteria, _, optionCriteriaArr) => <OptionCriteria
                                    optionCriteria={optionCriteria}
                                    secondStageScoreIdsToCheck={secondStageScoreIdsToCheck}
                                    option={option}
                                    columnsToShow={columnsToShow}
                                    optionCriteriaArr={optionCriteriaArr}
                                    activeCriterias={activeCriterias}
                                    isShortList={isShortList}
                                    isDecisionSample={decision?.is_sample || is_shared}
                                    expandedFacts={expandedFacts}
                                    disabled={is_sample || secure_token || is_shared}
                                    criteriaTitleMap={criteriaTitleMap}
                                    onCriteriaChange={handleCriteriaChange(options)}
                                    onRubricSave={handleRubricSave}
                                    index={index}
                                    key={optionCriteria.id}
                                    updateItems={updateItems}
                                  />
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={cc(classes.customScroll, classes.vertical, { [classes.at]: isAllocatorUser, [classes.readonly]: is_shared })}
                ref={$scrollbarY}
              >
                <span></span>
              </div>
              <div
                className={cc(classes.customScroll, classes.horizontal)}
                ref={$scrollbarX}
                style={{ marginLeft: width }}
              >
                <span></span>
              </div>

              {is_sample || is_shared || secure_token || isAllocatorUser ? null : (
                <form
                  className={cc(classes.addOptionContainer/* , {[classes.dark]: isAllocatorUser} */)}
                  onSubmit={onSubmitHandle}
                >
                  <span>Add More Options</span>
                  <div style={{ position: "relative" }}>

                    <input
                      ref={$input}
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      type="number"
                      max={30}
                      min={1}
                      required
                    />
                    <div className={classes.arrow}>
                      <ArrowUp
                        onClick={() => setValue(parseInt(value) + 1)}
                        style={{ width: "14px", height: "14px" }}
                      />
                      <ArrowDown
                        onClick={() => value > 0 && setValue(parseInt(value) - 1)}
                      />
                    </div>

                  </div>
                  <button>Add</button>
                </form>
              )}
            </div>
          )}
        </AppLayout>
      </div>

      {isShowFinalizeDecisin && (
        <FinalizeDecision
          decision={decision}
          options={options}
          onClose={() => setIsShowFinalizeDecisin(false)}
          onShowWinner={() => setIsShowWinner(true)}
        />
      )}
      {isShowWinner && !loading && (
        <WinnerModal onClose={() => setIsShowWinner(false)} />
      )}
      {!isAddOption || (
        <div className={classes.addOptions}>
          <AddOptionsModal
            value={"option"}
            onClose={() => setIsAddOptions(false)}
          />
        </div>
      )}

      <ManageCriteria />

      {criteriaForChanging?.showPopup &&
        !decision.is_sample &&
        !is_shared &&
        !shareDecision?.shared_view &&
        !secure_token && (
          <CreateOptionCriteria
            criteriaForChanging={criteriaForChanging}
            setCriteriaForChanging={setCriteriaForChanging}
            // isShortList={columnsToShow?.isShortlisted}
            isShortList={isShortList}
            setExpandedFacts={setExpandedFacts}
            CriteriaPayload={CriteriaPayload}
            setCriteriaPayload={setCriteriaPayload}
            scoreData={scoreData}
            setScoreData={setScoreData}
            factsPayload={factsPayload}
            setFactsPayload={setFactsPayload}
          />
        )}
      {aiAssistant && (
        <AIAssistantModal
          criteria={activeCriterias}
          setOptionCriteriaProsCons={setOptionCriteriaProsCons}
          onClose={() => setAiAssistant(false)}
          pros={pros}
          cons={cons}
          selectedCriteria={selectedCriteria}
        />
      )}
      {isScoringRubicModal && (
        <AIScoringRubicModal
          decisionID={decisionId}
          criteria={activeCriterias}
          setOptionCriteriaProsCons={setOptionCriteriaProsCons}
          onClose={() => setIsScoringRubicModal(false)}
          selectedCriteria={selectedCriteria}
        />
      )}
      {isGenerateCriteriaModal && (
        <AIGenerateCriteriaModal
          criteria={activeCriterias}
          setOptionCriteriaProsCons={setOptionCriteriaProsCons}
          onClose={() => setIsGenerateCriteriaModal(false)}
          selectedCriteria={selectedCriteria}
        />
      )}
    </div>
  );
};

export { DecisionDetails };
