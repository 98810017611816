import moment from "moment";
import { Dropdown, Progress } from "antd";
import { COLORS } from "constants/colors";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteModal from "scenes/PlanDetails/Modal/DeleteModal";
import { archivePlan, removePlan } from "modules/actions/PlanActions";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import RenamePlanModal from "scenes/PlanDetails/Modal/RenamePlanModal";
import { formatDate } from "../../../utils/helper";
import { ReactComponent as Calander } from "assets/svg/calander.svg";

import { ReactComponent as RenameIcon } from "assets/svg/rename.svg";
import { ReactComponent as ArchiveIcon } from "assets/svg/archive.svg";
import { ReactComponent as TrashICon } from "assets/svg/trashSmall.svg";
import { ReactComponent as ShareArrow } from "assets/svg/shareArrow.svg";
import { ReactComponent as Dots } from "assets/svg/dots.svg";

export default function PlanList({
  item,
  loadingUpdatePlan,
  handleUpdatePlan,
  isHierarchy,
  index,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loadingDelete, loadingArchive } = useSelector(
    (state) => state.plan.plan
  );

  const { users } = useSelector((state) => state.user.projectOwners);

  const [state, setState] = useState({
    deleteModal: false,
    renamePlanModal: false,
    deleteArchiveModal: false,
    planName: item?.name || "",
  });

  const { deleteModal, renamePlanModal, deleteArchiveModal } = state;

  const start_line = formatDate(item?.start_line);
  const dead_line = formatDate(item?.dead_line);

  const handleArchive = () => {
    const payload = {
      project_plan: parseInt(item?.id),
      is_archived: !item?.is_archived,
    };

    dispatch(archivePlan(payload, goBack));
  };

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const goBack = () => {
    handleChange("deleteModal", false);
    handleChange("renamePlanModal", false);
    handleChange("deleteArchiveModal", false);
  };

  const handleRemove = (e) => {
    dispatch(removePlan(item?.id, goBack));
  };

  const onClickHandle = (event) => {
    if (!item?.is_archived) {
      if (
        !event.target.closest(".ant-dropdown") &&
        !event.target.closest("#test")
      )
        navigate(
          `/project-planner/plan-details/${item?.id}/?hierarchy=${isHierarchy}`
        );
      localStorage.removeItem("isAIGenerate");
    }
  };

  const goalProgress = Number(item?.goal_progress * 100 || 0).toFixed(0);
  const planProgress = Number(item?.plan_progress * 100 || 0).toFixed(0);

  const getMenuProps = (item) => ({
    className: "removeOption",
    items: [
      {
        key: "1",
        onClick: () => {
          handleChange("renamePlanModal", true);
          handleChange("planName", item?.name);
        },
        label: (
          <span className={styles.menuItem}>
            <RenameIcon /> Rename
          </span>
        ),
      },
      {
        key: "2",
        onClick: handleArchiveModal,
        label: (
          <span
            className={styles.menuItem}
            style={{
              opacity: item?.is_archived ? 0.5 : 1,
            }}
          >
            <ArchiveIcon />
            {item?.is_archived ? "Unarchive Plan" : "Archive Plan"}
          </span>
        ),
      },
      {
        key: "3",
        onClick: handleShare,
        disabled: item?.is_archived,
        label: (
          <span className={styles.menuItem}>
            <ShareArrow />
            Share
          </span>
        ),
      },
      {
        key: "4",
        onClick: handleRemoveModal,
        label: (
          <span className={styles.deleteItem}>
            <TrashICon />
            Delete Project
          </span>
        ),
      },
    ],
  });

  const handleArchiveModal = (e) => {
    handleChange("deleteArchiveModal", true);
  };

  const handleShare = () => {
    navigate(`/project-planner/plan-details/${item?.id}/resources`);
  };

  const handleRemoveModal = (e) => {
    handleChange("deleteModal", true);
  };

  return (
    <>
      {index === 0 && (
        <ul className={styles.listHeader}>
          <li>Project Name</li>
          <li>Goal Progress</li>
          <li>Plan Progress</li>
          <li>Last Update</li>
          <li>Start</li>
          <li>Deadline</li>
          <li>Assignee</li>
          <li></li>
        </ul>
      )}

      <div className={styles.planList} onClick={onClickHandle}>
        <h2 className={styles.title}>{item?.name}</h2>

        <div className={styles.progressWrapper}>
          <div className={styles.text}>
            {Number((item?.goal_progress || 0) * 100).toFixed(0)}%
          </div>
          <Progress
            size="small"
            percent={goalProgress}
            strokeColor={
              goalProgress >= 70
                ? COLORS.sliderGreen
                : goalProgress >= 30
                ? COLORS.orange
                : COLORS.red
            }
            style={{ width: "100%" }}
            showInfo={false}
          />
        </div>

        <div className={styles.progressWrapper}>
          <div className={styles.text}>
            {Number((item?.plan_progress || 0) * 100).toFixed(0)}%
          </div>
          <Progress
            size="small"
            percent={Number(item?.plan_progress * 100 || 0).toFixed(0)}
            strokeColor={
              planProgress >= 70
                ? COLORS.sliderGreen
                : planProgress >= 30
                ? COLORS.orange
                : COLORS.red
            }
            style={{ width: "100%" }}
            showInfo={false}
          />
        </div>

        <span className={styles.update}>
          {moment(item?.updated_at).fromNow()}
        </span>

        <span className={styles.deadline}>
          {start_line}
          <Calander />
        </span>
        <span className={styles.deadline}>
          {dead_line}
          <Calander />
        </span>

        <div className={styles.avatar}>
          <div className="greyBox_dashbaord">
            {users
              .find((user) => user.id === (item?.owner?.id || item?.owner))
              ?.username?.slice(0, 2) ||
              users
                .find((user) => user.id === (item?.owner?.id || item?.owner))
                ?.email?.slice(0, 2)}
          </div>
        </div>

        <div
          className={styles.dropdownMenu}
          onClick={(e) => e.stopPropagation()}
        >
          <Dropdown
            placement="bottomRight"
            click
            className={styles.menuDots}
            menu={getMenuProps(item)}
            trigger={["click"]}
            overlayClassName="antd-dropDown-borderRadius"
            onClick={(e) => e.stopPropagation()}
          >
            <Dots />
          </Dropdown>
        </div>
      </div>

      <DeleteModal
        visible={deleteModal}
        heading={"Delete Plan"}
        onClose={() => handleChange("deleteModal", false)}
        descriptions={
          <span>
            Are you sure you want to delete <b>"{item?.name}"</b> project plan?
          </span>
        }
        planDetails={item}
        handleChange={handleChange}
        handleArchive={handleArchive}
        handleRemove={handleRemove}
        loadingArchive={loadingArchive}
        loading={loadingDelete}
      />

      <DeleteModal
        visible={deleteArchiveModal}
        isPlan
        item={item}
        heading={item?.is_archived ? "Send To Unarchive" : "Send To Archive"}
        onClose={() => handleChange("deleteArchiveModal", false)}
        descriptions={
          <span>
            Are you sure you want to{" "}
            {item?.is_archived ? "unarchive" : "archive"} <b>"{item?.name}"</b>{" "}
            project plan?
          </span>
        }
        planDetails={item}
        handleChange={handleChange}
        handleRemove={handleArchive}
        // handleArchive={handleArchive}
        loading={loadingArchive}
      />

      <RenamePlanModal
        visible={renamePlanModal}
        planDetails={item}
        handleChange={handleChange}
        handleSubmit={(payload) => handleUpdatePlan(item?.id, payload, goBack)}
        loading={loadingUpdatePlan}
      />
    </>
  );
}
