import { Modal, Table } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
// @ts-ignore
import styles from "./styles.module.scss";

import { ReactComponent as Cross } from "assets/svg/deleteIcon.svg";
import { ReactComponent as Drag } from "assets/svg/dragrow.svg";
import { ReactComponent as TrashIcon } from "assets/svg/trashGray.svg";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import WeightTitle from "./WeightTitle";
import { generateUniqueId } from "../../../utils/helper";
import { DeleteItemModal } from "../ActionItems/DeleteItemModal";
import WeightInput from "./WeightInput";
import { toast } from "react-toastify";
import {
  changeCategoryItemsOrder,
  adjustCategoryWeight,
  // @ts-ignore
} from "modules/actions/PlanActions";

const WeightModel = () => {
  const inputRef = useRef<any>({});
  const dispatch = useDispatch();
  const MemoizedWeightInput = React.memo(WeightInput);
  const [isNewCategory, setIsNewCategory] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [DeleteModalData, setDeleteModalData] = useState<any>({});

  const [sortedCategories, setSortedCategories] = useState<any[]>([]);
  const [DraggableData, setDraggableData] = useState<any[]>([]);
  const [updateCategory, setUpdateCategory] = useState<any[]>([]);
  const [sumOfWeight, setSumOfWeight] = useState(0);
  const [edit, setEdit] = useState(false);
  const [isDrag, setIsDrag] = useState(false);  

  const {
    weightModel,
    planDetails,
    loadingAdjustCategoryWeight,
    newCategory,
    loading,
  } = useSelector(
    // @ts-ignore
    (state) => state.plan.plan
  );

  const onClose = () => {
    dispatch({
      type: "WEIGHT_MODEL",
      payload: false,
    });
    setIsDeleteModalVisible(false);
    setDeleteModalData({});
    setIsNewCategory(false);
    setSortedCategories([]);
    setUpdateCategory([]);
    setDraggableData([]);
  };

  const handleClose = () => {
    dispatch({
      type: "WEIGHT_MODEL",
      payload: false,
    });
    setIsDeleteModalVisible(false);
    setDeleteModalData({});
    setIsNewCategory(false);
    setSortedCategories([]);
    setUpdateCategory([]);
    setDraggableData([]);
  };

  const handleAddCategory = () => {
    setIsNewCategory(true);
    const newCategory = {
      key: sortedCategories.length,
      id: generateUniqueId(),
      name: "",
      weight: 0,
      isNew: true,
      project_plan: planDetails?.id,
      dead_line: planDetails?.dead_line,
      start_line: planDetails?.start_line,
    };
    setSortedCategories([...sortedCategories, newCategory]);
    setEdit(true);
  };

  const foucusInput = (id: any) => {
    if (inputRef.current[id]) {
      inputRef.current[id].focus();
    }
  };

  const columns = [
    {
      title: () => (
        <div className="weight-header-actions">
          Category Name
          <button onClick={handleAddCategory} disabled={edit || loading}>
            <PlusOutlined /> Add Category
          </button>
        </div>
      ),
      dataIndex: "name",
      width: "30%",
      render: (text: any, record: any, index: number) => (
        <div className={styles.categoryName}>
          <Draggable draggableId={record.key.toString()} index={index}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{ display: "inline-block" }}
              >
                <Drag />{" "}
              </div>
            )}
          </Draggable>

          <WeightTitle
            data={record}
            isNewCategory={isNewCategory}
            sortedCategories={sortedCategories}
            setSortedCategories={setSortedCategories}
            setEdit={setEdit}
          />
        </div>
      ),
    },
    {
      title: "Weight",
      dataIndex: "age",
      width: "60%",
      render: (text: any, record: any) => (
        <>
          <MemoizedWeightInput
            inputRef={inputRef}
            data={record}
            sortedCategories={sortedCategories}
            setSortedCategories={setSortedCategories}
            foucusInput={foucusInput}
            updateCategory={updateCategory}
            setUpdateCategory={setUpdateCategory}
          />
        </>
      ),
    },
    {
      width: "10%",
      title: "Actions",
      dataIndex: "address",
      render: (text: any, record: any) => (
        <div className="cursor-pointer">
          <TrashIcon
            onClick={() => {
              setIsDeleteModalVisible(true);
              setDeleteModalData({
                id: record.id,
                name: record.name,
                weight: record.weight,
              });
            }}
          />
        </div>
      ),
    },
  ];

  const DragableBodyRow = (props: any) => {
    const index = sortedCategories?.findIndex(
      (row) => row?.key === props["data-row-key"]
    );

    return (
      <Draggable
        key={props["data-row-key"]}
        draggableId={props["data-row-key"]?.toString()}
        index={index}
      >
        {(provided, snapshot) => (
          <tr
            {...props}
            {...provided.draggableProps}
            ref={provided.innerRef}
            className={snapshot.isDragging ? "hello" : props.className}
            style={{
              ...props.style,
              ...provided.draggableProps.style,
            }}
          />
        )}
      </Draggable>
    );
  };

  const draggableBody = (props: any) => {
    return (
      <Droppable droppableId="abcd">
        {(provided) => (
          <>
            <tbody
              {...props}
              {...provided.droppableProps}
              ref={provided.innerRef}
            ></tbody>
            {provided.placeholder}
          </>
        )}
      </Droppable>
    );
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = reorder(
      sortedCategories,
      result.source.index,
      result.destination.index
    );
    setIsDrag(true)
    setSortedCategories(items);
    setDraggableData(items as any);
  };

  const components = {
    body: {
      wrapper: draggableBody,
      row: DragableBodyRow,
    },
  };

  useEffect(() => {
    setEdit(false);

    const formatedData =
      planDetails?.categories?.map((item: any, index: number) => ({
        key: index,
        id: item.id,
        name: item.name,
        weight: Number(item.weight) * 100,
        project_plan: item.project_plan,
      })) || [];

    let reorderedData = [];

    if (DraggableData?.length > 0) {
      reorderedData = DraggableData.map((draggableItem: any) =>
        formatedData.find((item: any) => item.id === draggableItem.id)
      ).filter(Boolean);

      const missingItems = formatedData.filter(
        (item: any) =>
          !DraggableData.some(
            (draggableItem: any) => draggableItem.id === item.id
          )
      );

      reorderedData = [...reorderedData, ...missingItems];
    } else {
      reorderedData = formatedData;
    }

    setSortedCategories(reorderedData);
  }, [planDetails, weightModel]);

  useEffect(() => {
    const formatedData = planDetails?.categories?.map(
      (item: any, index: number) => {
        return {
          key: index,
          id: item.id,
          name: item.name,
          weight: Number(item.weight) * 100,
          project_plan: item.project_plan,
        };
      }
    );

    setUpdateCategory(formatedData || []);
  }, [weightModel]);

  useEffect(() => {
    if (newCategory) {
      const formatedData = {
        id: newCategory?.id,
        name: newCategory?.name,
        weight: Number(newCategory?.weight) * 100,
        project_plan: newCategory?.project_plan,
      };
      setUpdateCategory([...updateCategory, formatedData]);
    }
  }, [newCategory]);

  useEffect(() => {
    const sumOfUpdatedWeight = updateCategory?.reduce((acc: any, item: any) => {
      return acc + Number(item.weight);
    }, 0);

    setSumOfWeight(sumOfUpdatedWeight);
  }, [sortedCategories, planDetails, updateCategory]);

  const handleSave = () => {
    if (sumOfWeight === 100) {
      if (sortedCategories?.length > 0) {
        const formatedWeight = updateCategory?.map((item) => {
          return {
            id: item.id,
            weight: (Number(item.weight) / 100).toFixed(2),
          };
        });

        const payload = {
          project_plan: planDetails?.id,
          categories: formatedWeight,
        };

        const categories: any = [];
        sortedCategories?.forEach((element, index) => {
          if (element) {
            categories?.push({
              id: element?.id,
              order_id: index,
            });
          }
        });

        const orderPayload = {
          project_plan: planDetails?.id,
          categories,
        };

        dispatch(changeCategoryItemsOrder(orderPayload, planDetails?.id));
        dispatch(adjustCategoryWeight(payload, planDetails?.id, handleClose));
        setIsDrag(false);
      } else {
        handleClose();
      }
    } else {
      toast("Sum of all weights should be 100%", { type: "error" });
    }
  };

  const handleCloseTrash = () => {
    setIsDeleteModalVisible(false);
    setDeleteModalData({});
    setIsNewCategory(false);
  };

  const isButtonDisabled = planDetails?.categories?.every((item: any) => {
    const matchingElement = updateCategory?.find(
      (element: any) => element.id === item.id
    );

    return matchingElement
      ? Number(item?.weight) * 100 === matchingElement?.weight
      : false;
  });

  return (
    <>
      <DeleteItemModal
        itemId={DeleteModalData?.id || ""}
        itemName={DeleteModalData?.name || ""}
        planId={planDetails?.id}
        type="category"
        isVisible={isDeleteModalVisible}
        onClose={handleCloseTrash}
        sumOfWeight={sumOfWeight}
        setSumOfWeight={setSumOfWeight}
        updateCategory={updateCategory}
        setUpdateCategory={setUpdateCategory}
      />

      <Modal
        footer={null}
        closable={false}
        onCancel={onClose}
        open={weightModel}
        className={"deleteModal"}
        width={1200}
        centered
      >
        <div className={styles.header}>
          <div className={styles.title}>Adjust Weight</div>
          <Cross onClick={onClose} className={"c-pointer"} />
        </div>

        <div className={styles.table}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Table
              pagination={false}
              dataSource={sortedCategories}
              columns={columns}
              rowKey="key"
              className="weight-table"
              components={components}
            />
          </DragDropContext>
        </div>

        <div className={styles.actions}>
          <h3
            className={styles.sumOfWeight}
            style={{
              color: sumOfWeight === 100 ? "#9bc961" : "#f00000",
            }}
          >
            Sum of all weights: {Math?.round(sumOfWeight)}%
          </h3>
          <div className={styles.actionButton}>
            <button onClick={onClose}>Cancel</button>
            <button
              disabled={
                (loadingAdjustCategoryWeight ||
                isButtonDisabled ||
                sumOfWeight !== 100 ) &&
                !isDrag
              }
              onClick={handleSave}
            >
              {loadingAdjustCategoryWeight ? <LoadingOutlined /> : "Save"}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WeightModel;
