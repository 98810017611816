import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

// @ts-ignore
import styles from "./styles.module.scss";

import { ReactComponent as ResizeIcon } from "assets/svg/resizeIcon.svg";
import { ReactComponent as InfoSmallIcon } from "assets/svg/infoSmallGray.svg";
import { ReactComponent as AiStarIcon } from "assets/svg/aiStar.svg";
import { ReactComponent as AIAssistant } from "assets/svg/aiAssistant.svg";

import { Popover, Tooltip } from "antd";

const InputResize = ({
  name,
  value,
  onChange,
  onBlur,
  words,
  label,
  placeholder,
  error,
  errorMessage,
  handleSocket,
  tooltipMessage,
  IsAiDrawerShow,
  toolTipPopover,
  updateTutorial,
  setToolTipPopover,
}: {
  name: string;
  value: string;
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
  words: number;
  label: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  handleSocket?: any;
  tooltipMessage?: string;
  IsAiDrawerShow?: boolean;
  toolTipPopover?: boolean;
  updateTutorial?: () => void;
  setToolTipPopover?: (value: boolean) => void;
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const maxLength = 500;

  const [wasPasted, setWasPasted] = useState(false);
  const [height, setHeight] = useState<number>(40);
  const [isWrap, setIsWrap] = useState<boolean>(false);
  const [showResizeIcon, setShowResizeIcon] = useState(false);
  const [threeDots, setThreeDots] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleMouseDown = (e: any): void => {
    const startY = e.clientY;
    const startHeight = textAreaRef.current?.offsetHeight || 84;

    const handleMouseMove = (e: MouseEvent): void => {
      const newHeight = startHeight + (e.clientY - startY);
      if (newHeight >= 40 && newHeight <= 300) {
        setHeight(newHeight);
      }
    };

    const handleMouseUp = (): void => {
      document.removeEventListener("mousemove", handleMouseMove as any);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove as any);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handlePaste = (event: React.ClipboardEvent<any>) => {
    setWasPasted(true);
    let pastedData = event.clipboardData.getData("text");

    pastedData = pastedData.replace(/\s+$/, "");

    const newValue = value + pastedData;

    if (newValue.length > 500) {
      setIsError(true);
    } else {
      setIsError(false);
    }

    const truncatedValue = newValue.slice(0, maxLength);

    onChange(truncatedValue);

    event.preventDefault();
  };

  const handleChange = (event: React.ChangeEvent<any>) => {
    setWasPasted(false);

    const value = event.target.value;

    if (value.length > 500) {
      setIsError(true);
    } else {
      setIsError(false);
    }

    onChange(value);
  };

  const checkContentOverflow = useCallback(() => {
    const textArea = textAreaRef.current;

    if (textArea) {
      const scrollWidth = textArea.scrollWidth;
      const clientWidth = textArea.clientWidth;

      if (scrollWidth > clientWidth) {
        setThreeDots(true);
      } else {
        setThreeDots(false);
      }

      if (scrollWidth > clientWidth || height > 43) {
        setShowResizeIcon(true);
      } else {
        setShowResizeIcon(false);
      }

      if (height > 43) {
        setIsWrap(true);
      } else {
        setIsWrap(false);
      }
    }
  }, [height]);

  useEffect(() => {
    checkContentOverflow();
  }, [value, height, checkContentOverflow]);

  useEffect(() => {
    const handleResize = () => checkContentOverflow();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [checkContentOverflow]);

  return (
    <div className={styles.inputGroup}>
      <label className={styles.label}>{label}</label>
      <div className={styles.inputWrapper}>
        <div
          className={styles.inputContainer}
          style={{
            background: isError ? "#FFF0F0" : "#fff",
          }}
          onClick={(e) => {
            e.stopPropagation();
            textAreaRef.current?.focus();
          }}
        >
          <textarea
            ref={textAreaRef}
            className={styles.input}
            style={{
              background: isError ? "#FFF0F0" : "",
              height: `${height}px`,
              whiteSpace: isWrap ? "pre-wrap" : "nowrap",
            }}
            value={value || ""}
            name={name}
            onBlur={(event) => onBlur && onBlur(event.target.value)}
            placeholder={placeholder}
            onChange={handleChange}
            onPaste={handlePaste}
          />
          {!isWrap && threeDots && <div className={styles.threedots}>...</div>}
          {tooltipMessage && (
            <div
              className={styles.ai_Icon}
              style={{
                // @ts-ignore
                opacity: toolTipPopover && 1,
                // @ts-ignore
                visibility: toolTipPopover && "visible",
              }}
            >
              {toolTipPopover && updateTutorial ? (
                <Popover
                  content={
                    <>
                      <div className={styles.ai_popover}>
                        <div className={styles.ai_popoverHeader}>
                          <AIAssistant />
                        </div>
                        <div className={styles.ai_popoverContent}>
                          <h3>Suggest a purpose</h3>
                          <h6>Tap the icon to generate purpose suggestions.</h6>

                          <div className={styles.ai_popoverAction}>
                            <button onClick={updateTutorial}>Got It</button>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  open={toolTipPopover}
                  overlayClassName="ai_popover"
                  placement="right"
                  showArrow={true}
                  onOpenChange={(visible) => {
                    if (!visible) {
                      updateTutorial();
                    }
                    setToolTipPopover && setToolTipPopover(visible);
                  }}
                  // @ts-ignore
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  <Tooltip
                    title={
                      !name
                        ? "Please enter project name first"
                        : "Suggest purposes"
                    }
                    overlayClassName="ai_tooltip"
                  >
                    <AiStarIcon
                      onClick={() => name && handleSocket("purpose")}
                    />
                  </Tooltip>
                </Popover>
              ) : (
                <Tooltip title={tooltipMessage} overlayClassName="ai_tooltip">
                  <AiStarIcon
                    onClick={() =>
                      IsAiDrawerShow &&
                      handleSocket(name == "idea" ? "brainstorm" : name)
                    }
                  />
                </Tooltip>
              )}
            </div>
          )}

          {showResizeIcon && (
            <div className={styles.resizeIcon} onMouseDown={handleMouseDown}>
              <ResizeIcon />
            </div>
          )}
        </div>

        {error && <label className={styles.error}>{errorMessage}</label>}

        <CharacterCounter
          currentCount={words}
          wasPasted={wasPasted}
          isError={isError}
        />
      </div>
    </div>
  );
};

export default InputResize;

const CharacterCounter = ({
  currentCount,
  wasPasted,
  isError,
}: {
  currentCount: number;
  wasPasted: boolean;
  isError?: boolean;
}) => {
  const maxLength = 500;

  return (
    <div className={styles.wordCount}>
      {isError ? (
        <>
          <InfoSmallIcon />
          {wasPasted ? (
            <>The content was truncated at the character limit.</>
          ) : (
            <>Exceeds the maximum character limit</>
          )}
        </>
      ) : (
        `${maxLength - currentCount} characters left`
      )}
    </div>
  );
};
