import React, { memo } from "react";
import isEqual from 'lodash.isequal';
import { Tooltip } from "antd";

import { Option } from "../../components/Option";

// Styles
import classes from "./decisiondetails.module.scss";
import cc from "classnames";

const progress = (pro, con) => {
  let sum = pro + con;
  let pros = `${(pro / sum) * 100}%`;
  let cons = `${(con / sum) * 100}%`;
  if (pros === cons) {
    return { pros: "50%", cons: "50%" };
  }
  return { pros, cons };
};

const OptionHead = ({ option, columnsToShow, isShortList, decisionId, index, isShared, errorIds, setError, items }) => {
  const getItemById = (idToFind) => {
    const foundItem = items?.find(item => item.id === idToFind);
    return foundItem || null; // Return null if no item is found
  };

  const itemObj = getItemById(option?.id);

  return (
    <div className={classes.row}>
      <div className={classes.column}>
        <div
          className={cc(classes.box2, {
            [classes.high]: option?.highlighted
          })}
        >
          <Option
            columnsToShow={columnsToShow}
            second_stage_adjusted_score={option?.second_stage_adjusted_score}
            adjusted_score={option?.adjusted_score}
            optionItem={option}
            isShortList={!isShortList}
            isShared={isShared}
            i={index}
            decisionId={decisionId}
            ids={errorIds}
            setIds={setError}
            score_criteria_percentage={option?.score_criteria_percentage}
          />
        </div>
      </div>

      {columnsToShow?.prosCons && (
        <div className={classes.column}>
          <div
            className={cc(classes.box1, {
              [classes.high]: option?.highlighted,
            })}
          >
            <Tooltip>
              <div className={classes.prosConsCount}>
                <span
                  style={{
                    color: "#7dab77",
                  }}
                  className="py-1"
                >
                  {option.pro_count}
                </span>
              </div>

              <div
                className={classes.proConstStatusBar}
              >
                <div
                  style={{
                    width: progress(
                      option.pro_count,
                      option.con_count
                    )?.pros,
                  }}
                ></div>
                <div
                  style={{
                    width: progress(
                      option.pro_count,
                      option.con_count
                    )?.cons,
                  }}
                ></div>
              </div>
              <div className={classes.prosConsCount}>
                <span
                  style={{
                    color: "#f06060",
                  }}
                  className="py-1"
                >
                  {option.con_count}
                </span>
              </div>
            </Tooltip>
          </div>
        </div>
      )}

      {columnsToShow?.weightedScore && (
        <div className={classes.column}>
          <div
            className={cc(classes.box1, {
              [classes.high]: option?.highlighted,
            })}
            style={{minHeight: (option?.id === itemObj?.id) ? `${itemObj?.height}px` : "74px"}}
          >
            <span>
              {isShortList
                ? option?.second_stage_weighted_score
                : Math.floor(option?.weighted_score)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(OptionHead, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
