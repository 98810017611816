// Collection types

// @ts-ignore
import sidebarItems from './sidebarItems'

export const dummyProfile =
  'https://res.cloudinary.com/zaafir-solutions/image/upload/v1667416550/Avatar_uxzobx.png'
export const dummyAvatar =
  'https://www.seekpng.com/png/detail/428-4287240_no-avatar-user-circle-icon-png.png'
export const ProfileImageBackground =
  'https://res.cloudinary.com/zaafir-solutions/image/upload/v1668178319/picture_hbpyyp.png'
export const ProfileImageBackgroundSm =
  'https://res.cloudinary.com/zaafir-solutions/image/upload/v1668178319/picture-sm_lbx0cw.png'
export const PlusIcon =
  'https://res.cloudinary.com/zaafir-solutions/image/upload/v1668178319/plus_a2bfmh.svg'

const statuses = [
  { key: 'Current', value: 'in_progress' },
  { key: 'Completed', value: 'completed' },
  { key: 'Future', value: 'scheduled' },
  { key: 'Archive', value: 'archive' },
]
const groupby = ['Status', 'Heirarchy', 'Prepared by']
const sortbylist = [
  'Name',
  // 'Priority',
  'Startline',
  'Deadline',
  'Goal Progress',
  'Plan Progress'
]

const weightsList = [
  { key: '10', value: 1 },
  { key: '9', value: 0.9 },
  { key: '8', value: 0.8 },
  { key: '7', value: 0.7 },
  { key: '6', value: 0.6 },
  { key: '5', value: 0.5 },
  { key: '4', value: 0.4 },
  { key: '3', value: 0.3 },
  { key: '2', value: 0.2 },
  { key: '1', value: 0.1 }
];

const categoryCount = [
  { key: '0', value: 0 },
  { key: '1', value: 1 },
  { key: '2', value: 2 },
  { key: '3', value: 3 },
  { key: '4', value: 4 },
  { key: '5', value: 5 },
  { key: '6', value: 6 },
  { key: '7', value: 7 },
  { key: '8', value: 8 },
  { key: '9', value: 9 },
  { key: '10', value: 10 }
]


const generateDurationList = (): { key: string; value: string }[] => {
  const durationList: { key: string; value: string }[] = [];
  const totalMinutes = 56 * 60; // 56 hours in minutes

  for (let minutes = 10; minutes <= totalMinutes; minutes += 10) {
    const days = Math.floor(minutes / (24 * 60));
    const hours = Math.floor((minutes % (24 * 60)) / 60);
    const remainingMinutes = minutes % 60;

    let key = '';
    let value = '';

    if (days > 0) {
      key += days + (days === 1 ? ' Day ' : ' Days ');
      value += days + ' ';
    }

    if (hours > 0) {
      key += hours + (hours === 1 ? ' Hr ' : ' Hrs ');
    }
    if (remainingMinutes > 0) {
      key += remainingMinutes + ' Min';
    } else {
      key = key.trim();
    }

    value += `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:00`;

    durationList.push({ key: key.trim(), value: value.trim() });
  }

  return durationList;
};

export const durationList = generateDurationList();

const GOAL_INFO =
  'Your Goal Progress shows how close you are to reaching your goal. It measures your current results compared to the results you desire.'
const PLAN_INFO =
  'Your Plan Progress shows how thoroughly you are working on your plan. It measures the completeness of action items.'
const CATEGORY_INFO =
  'Your Result Progress shows how well you navigate the moving parts to reach your goal. It measures the completeness of categories.'

export {
  sidebarItems,
  weightsList,
  categoryCount,
  statuses,
  sortbylist,
  groupby,
  GOAL_INFO,
  PLAN_INFO,
  CATEGORY_INFO
}
