import { deleteCons } from "api";
import { Error } from "utils/Response";
import { GetCons } from "../GetCons/action";
import { ActionTypes } from "./types";
import { GetDecisionDetails } from "../GetDecisionDetails/action";
import { GetDescriptionsSummary } from "redux/DecisionMatrix/GetDecisionSummary/action";


export const DeleteCons = (Data, id, decisionId, optionId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await deleteCons(Data);
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
    if (status === 204) {
      dispatch(GetCons(id));
      if (optionId) {
        dispatch(GetDescriptionsSummary(optionId));
      }
      dispatch(GetDecisionDetails(decisionId, false));
    }
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
