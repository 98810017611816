import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 45px;
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  scroll-behavior: smooth;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
`;

export const Head = styled.div<{ $color?: string; isShort: boolean }>`
  display: flex;
  align-items: center;
  height: 44px;
  background: var(--Primary-400, #418db8);
  position: sticky;
  top: 0;
  z-index: 2;
  padding-left: 6px;
  min-width: ${({ isShort }) => (isShort ? "1200px" : "1680px")};

  &::before {
    content: "";
    min-width: 6px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${({ $color }) =>
    $color &&
    css`
      &::before {
        background: ${$color};
      }
    `}
`;

const sizes = [
  "24.60",
  "8.09",
  "7.26",
  "5.77",
  "5.77",
  "7.03",
  "8.40",
  "8.17",
  "6.63",
  "7.66",
  "7.77",
];

export const HeadItem = styled.span<{ $center?: boolean }>`
  display: flex;
  align-items: center;
  height: 44px;
  /* border-right: 1px solid var(--Primary-300, #71aaca); */
  color: #171717;
  background-color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  padding: 12px;
  width: 100%;
  min-width: 10%;

  ${({ $center }) =>
    $center &&
    css`
      text-align: center;
      justify-content: center;
    `}

  &:nth-child(1) , &:nth-child(2)  , &:nth-last-child(1) {
    position: relative;
    min-width: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:nth-child(3) {
    min-width: 32%;

    &:hover {
      .add-catrgory {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  span {
    font-weight: 500;
    font-size: 12px;
    color: #ff9900;
    margin-left: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
  }
`;

const SpecialButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 28px;
  background: transparent;
  padding: 0;
  border: none;
  transform: rotate(-45deg);
  margin-top: -16px;
  margin-left: -10px;
  position: absolute;

  img {
    transform: rotate(45deg);
  }
`;

export const BtnAddCategory = styled.button`
  ${SpecialButton};
  border-bottom: 1px solid var(--Primary-300, #71aaca);
  outline: none;
`;

export const BtnExpand = styled.button`
  ${SpecialButton};
  margin-top: 14px;
  margin-left: 8px;
  outline: none;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  // overflow-y: auto;
`;

export const CustomizePopover = styled.div`
  width: 260px;
  padding: 18px 16px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px; // Set the width of the scrollbar
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1 !important; // Background of the scrollbar track
    z-index: -1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ced0d4 !important; // Color of the scrollbar thumb
    border-radius: 10px; // Rounded corners
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #aeb0b5 !important; // Darker color on hover
  }

  /* Firefox scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: #ced0d4 #f1f1f1; // Thumb and track color for Firefox
`;

export const CustomizeSubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  h5 {
    font-size: 12px;
    font-weight: 600;
    color: #262626;
    margin: 0;
    padding: 0;
    font-family: "Inter";
  }

  button {
    border: none;
    background: transparent;
    outline: none;
    font-size: 12px;
    font-weight: 600;
    color: #1271a6;
    margin: 0;
    padding: 0;
    font-family: "Inter";
    cursor: pointer;

    :disabled {
      color: #a0c6db;
      cursor: not-allowed;
    }
  }
`;

export const CustomizeHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  h6 {
    margin: 0;
    padding: 0;
    color: #1b2a3d;
    font-size: 14px !important;
    font-weight: 600;
  }
`;

export const CustomizeItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CustomizeItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .customizeItem-title {
    display: flex;
    align-items: center;
    gap: 10px;

    h4 {
      margin: 0;
      padding: 0;
      color: #1b2a3d;
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

export const CardViewWrapper = styled.div`
  padding: 0px 10px 20px 20px;
  width: 100%;
  display: flex;
  gap: 10px;
  overflow: auto;
`;

export const AIGenerationLoading = styled.div`
  background-color: white;
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

    img {
      margin-top: 50px;
    }
    p {
      font-size: 18px;
      font-weight: 700;
    }
  }
`;
