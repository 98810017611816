export interface UnitDataItem {
  id?: string;
  label: string;
  value: string;
  items: string[];
}

export const unitsData: UnitDataItem[] = [
  {
    label: "Weight",
    value: 'weight',
    items: ['kg',  'lbs']
  },
  {
    label: "Height",
    value: 'height',
    items: ['cm', 'ft', 'inches']
  },
  {
    label: "Distance",
    value: 'distance',
    items: ['km', 'miles']
  },
  {
    label: "Energy",
    value: 'energy',
    items: ['kcal', 'kj']
  },
  {
    label: "Time",
    value: 'time',
    items: ['minutes', 'hours']
  },
  {
    label: "Volume",
    value: 'volume',
    items: ['cups', 'fl oz', 'ml']
  },
  {
    label: "Count",
    value: 'count',
    items: ['#']
  },
  {
    label: "Scale",
    value: 'scale',
    items: ['%']
  },
  {
    label: "Currency",
    value: 'currency',
    items: ['$', '£', '€']
  }
];

export const ThresholdOptions = [
  {
    value: 1,
    label: "1 hr",
  },
  {
    value: 2,
    label: "2 hr",
  },
  {
    value: 3,
    label: "3 hr",
  },
  {
    value: 4,
    label: "4 hr",
  },
  {
    value: 5,
    label: "5 hr",
  },
  {
    value: 6,
    label: "6 hr",
  },
  {
    value: 7,
    label: "7 hr",
  },
  {
    value: 8,
    label: "8 hr",
  },
  {
    value: 9,
    label: "9 hr",
  },
  {
    value: 10,
    label: "10 hr",
  },
  {
    value: 11,
    label: "11 hr",
  },
  {
    value: 12,
    label: "12 hr",
  },
  {
    value: 13,
    label: "13 hr",
  },
  {
    value: 14,
    label: "14 hr",
  },
  {
    value: 15,
    label: "15 hr",
  },
  {
    value: 16,
    label: "16 hr",
  },
  {
    value: 17,
    label: "17 hr",
  },
  {
    value: 18,
    label: "18 hr",
  },
  {
    value: 19,
    label: "19 hr",
  },
  {
    value: 20,
    label: "20 hr",
  },
  {
    value: 21,
    label: "21 hr",
  },
  {
    value: 22,
    label: "22 hr",
  },
  {
    value: 23,
    label: "23 hr",
  },
  {
    value: 24,
    label: "24 hr",
  },
];

export const defaultColumns = [
  {
    id: 1,
    key: "weight",
    title: "Weight",
    isVisible: true,
  },
  {
    id: 2,
    key: "plan_progress",
    title: "Plan Progress",
    isVisible: true,
  },
  {
    id: 3,
    key: "status",
    title: "Status",
    isVisible: true,
  },
  {
    id: 4,
    key: "measurement",
    title: "Measurement",
    isVisible: true,
  },
  {
    id: 5,
    key: "start_date",
    title: "Start Date",
    isVisible: true,
  },
  {
    id: 6,
    key: "due_date",
    title: "Due Date",
    isVisible: true,
  },
  {
    id: 7,
    key: "duration",
    title: "Duration",
    isVisible: true,
  },
  {
    id: 8,
    key: "assignee",
    title: "Assignee",
    isVisible: true,
  },
];
